<template>
    <div class="animated fadeIn">
        <div id="snackbar"></div>
        <b-card>
            <b-card-header>
                Finance Report 
            </b-card-header>
            <b-card-body>
                <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
                
                </v-client-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import CineAsiaDistributorService from "@/services/CineAsiaDistributorService";

export default {
  name: "Cinema-Finance-Report",
  components: {
    Event,
    cSwitch
  },
  data() {
    return {
      columns: ["CinemaName", "ShowDate", "TransferAmount", "TransferID"],
      data: [],
      options: {
        headings: {
          CinemaName: "CinemaName",
          ShowDate: "Show Date",
          TransferAmount: "Amount",
          TransferID: "Transfer ID"
        },
        sortable: ["CinemaName", "ShowDate", "TransferAmount", "TransferID"],
        filterable: ["CinemaName", "ShowDate", "TransferAmount", "TransferID"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getCinemaFinance();
  },
  methods: {
    getCinemaFinance: function() {
      CineAsiaDistributorService.getCinemaFinance()
        .then(response => {
          const { data } = response;
          if (data.Status && data.FinanceReportList.length > 0) {
            this.data = data.FinanceReportList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
